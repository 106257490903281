import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@lui/core/services';
import { Subject } from 'rxjs';
import { DepartmentPermissionQuery } from '../models/department-permission-query.model';

@Directive({
    selector: '[hasPermissionInAnyDepartment]',
})
export class HasPermissionInAnyDepartmentDirective implements OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();
    private hasView: boolean = false;
    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthService) {}

    @Input() set hasPermissionInAnyDepartment(permissionQuery: DepartmentPermissionQuery) {
        const can: boolean = this.authService.userHasPermissionInAnyDepartment(permissionQuery.departmentIds, permissionQuery.permission);
        if (can && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!can && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
