<div class="map" id="map"></div>
<div *nzModalFooter>
    <nz-form-label nzRequired [nzSm]="8" [nzXs]="24" nzFor="advancePaymentTypeId">Komentārs</nz-form-label>
    <input
        type="text"
        nz-input
        class="img-comment"
        [(ngModel)]="comment"
        placeholder="Komentārs"
        id="img-comment"
        [ngModelOptions]="{ standalone: true }"
    />

    <button nz-button nzType="default" (click)="cancel()">Atcelt</button>
    <button nz-button nzType="primary" [disabled]="!comment" (click)="addImage()">Pievienot attēlu</button>
</div>
