import { Account } from './account.model';
import { Address } from './address.model';
import { BaseModel } from './base-model.model';
import { File } from './file.model';

export interface Company extends BaseModel {
    name: string;
    regNr: string;
    prefix: string;
    phone: string;
    companyEmail: string;
    bookkeeperEmail: string;
    personnelEmail: string;
    invoiceFooterInfo: string;
    invoiceFooterText: string;
    invoiceHeaderImage: File;
    estimateHeaderImage: File;
    transportCosts: number;
    fuelPrivateWithholdingRate: number;
    capitalUtilizationRate: number;
    profitMargin: number;
    isActive: boolean;
    address: Address;
    defaultVat: number;
    defaultVatTypeId: number;
    correspondenceMailDefaultAnswerDays: number;
    accountId: number;
    departmentIds: number[];
    account: Account;
    additionalAccountId: number;
    additionalAccount: Account;
    bossId: number;
    invoiceShowAccount: string;
    minimumVacationDurationDays: number;
    savedById: number;
    comparisonActDefaultOnDate: string;
    comparisonActDefaultFirstAccount: string;
    comparisonActBookkeeperPerformerId: number;
}

export enum PrintoutImageType {
    Bill = 1,
    Estimate = 2,
}
