import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Bank } from '@lui/shared/models/bank.model';
import { BaseServiceWithFilter } from './base.service';
import { BankFilter } from '@lui/shared/models/bank-filter.model';

@Injectable({ providedIn: 'root' })
export class BanksService extends BaseServiceWithFilter<Bank, BankFilter> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/api/v1/banks/');
    }
}
