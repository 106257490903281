import { Component, OnInit, Type } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { ClassifierService } from '@lui/core/services';
import { Classifier, ClassifierType, Client, Account, PersonTypes, ClientTypes } from '@lui/shared/models';
import { UpsertAddressComponent } from '../upsert-address/upsert-address.component';
import { Address } from '@lui/shared/models/address.model';
import { BaseUpsertComponent } from '@lui/core/components/base-upsert-component';
import { Observable } from 'rxjs/internal/Observable';
import { ClientsService } from '@lui/core/services/clients.service';
import { UpsertAccountComponent } from '../upsert-account/upsert-account.component';
import { codeValidator } from '@lui/core/validators/code.validator';
import { AsyncValidatorService } from '@lui/core/validators/async-validator.service';
import { startWith } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PersonCodeType } from '@lui/core/validators/person-code-type.enum';

@Component({
    selector: 'lui-upsert-client',
    templateUrl: 'upsert-client.component.html',
})
export class UpsertClientComponent extends BaseUpsertComponent<Client, ClientsService> implements OnInit {
    personTypes$: Observable<Classifier[]>;
    clientTypes$: Observable<Classifier[]>;
    sexes$: Observable<Classifier[]>;

    upsertAddressComponent: Type<UpsertAddressComponent> = UpsertAddressComponent;
    upsertAccountComponent: Type<UpsertAccountComponent> = UpsertAccountComponent;

    address: Address = {} as Address;
    account: Account = {} as Account;

    isAddressValid: boolean = false;
    isAccountValid: boolean = false;

    isSubmitPerformed: boolean = false;

    selectedPersonType: PersonTypes;
    personTypes: typeof PersonTypes = PersonTypes;
    clientTypes = ClientTypes;

    initialCode: string;

    constructor(
        private modal: NzModalRef,
        private classifierService: ClassifierService,
        private clientsService: ClientsService,
        private asyncValidatorService: AsyncValidatorService,
        messageService: NzMessageService,
        private fb: UntypedFormBuilder,
    ) {
        super(clientsService, messageService);

        // set modal texts
        this.saveSuccessText = 'Klients saglabāts!';
        this.createObjectSaveErrorText = 'Kļūda pievienojot klientu';
        this.updateObjectSaveErrorText = 'Kļūda labojot klientu';
    }

    async ngOnInit(): Promise<void> {
        this.personTypes$ = this.classifierService.getClassifiers(ClassifierType.PersonTypes);
        this.clientTypes$ = this.classifierService.getClassifiers(ClassifierType.ClientTypes);
        this.sexes$ = this.classifierService.getClassifiers(ClassifierType.Sexes);
    }

    createForm(): UntypedFormGroup {
        return this.fb.group({
            personTypeId: [this.personTypes.Physical, [Validators.required]],
            clientTypeId: [null],
            sex: [null, [Validators.required]],
            name: [null, [Validators.required, Validators.maxLength(32)]],
            surname: [null, [Validators.required, Validators.maxLength(128)]],
            code: [null, { validators: [Validators.required, Validators.maxLength(13), codeValidator], updateOn: 'blur' }],
            phone: [null, [Validators.required, Validators.maxLength(32)]],
            clientEmail: [null, [Validators.email, Validators.maxLength(128)]],
            bookkeeperEmail: [null, [Validators.email, Validators.maxLength(128)]],
            notes: [null, [Validators.maxLength(2000)]],
            isBlocked: [false],
        });
    }

    createAndFillForm(): UntypedFormGroup {
        return this.fb.group({
            personTypeId: [this.originalObject.personTypeId, [Validators.required]],
            clientTypeId: [this.originalObject.clientTypeId],
            sex: [this.originalObject.sex, [Validators.required]],
            name: [this.originalObject.name, [Validators.required, Validators.maxLength(32)]],
            surname: [this.originalObject.surname, [Validators.required, Validators.maxLength(128)]],
            code: [{ value: this.originalObject.code, disabled: true }, [Validators.required, Validators.maxLength(13), codeValidator]],
            phone: [this.originalObject.phone, [Validators.required, Validators.maxLength(128)]],
            clientEmail: [this.originalObject.clientEmail, [Validators.email, Validators.maxLength(128)]],
            bookkeeperEmail: [this.originalObject.bookkeeperEmail, [Validators.email, Validators.maxLength(128)]],
            notes: [this.originalObject.notes, [Validators.maxLength(2000)]],
            isBlocked: [!!this.originalObject.isBlocked],
        });
    }

    async setEditMode(client: Client): Promise<void> {
        super.setEditMode(client);
        this.address = client.address;
        this.account = client.account;
    }

    async save(): Promise<void> {
        const client = <Client>this.objectForm.getRawValue();
        client.address = this.address;

        if (!this.isAccountEmpty) {
            client.account = this.account;
        }

        if (this.isEditMode) {
            await this.clientsService.update(this.objectId, Object.assign(this.originalObject, client));
            this.modal.close(true);
            this.loading = false;
            return;
        }
        await this.clientsService.create(client);
    }

    afterInit(): void {
        this.initialCode = !this.originalObject ? null : this.originalObject.code;
        const codeControl = this.objectForm.get('code');
        this.objectForm
            .get('personTypeId')
            .valueChanges.pipe(startWith(this.isEditMode ? this.originalObject.personTypeId : this.personTypes.Physical))
            .subscribe((value) => {
                this.selectedPersonType = value;
                if (value) {
                    codeControl.setAsyncValidators(
                        this.asyncValidatorService.personCodeValidator(
                            this.initialCode,
                            !this.originalObject?.id ? 0 : this.originalObject.id,
                            PersonCodeType.Client,
                        ),
                    );
                } else {
                    codeControl.clearAsyncValidators();
                }

                codeControl.markAsDirty();
                codeControl.updateValueAndValidity();
            });
    }

    async validateAndSave(): Promise<void> {
        this.loading = true;
        this.isSubmitPerformed = true;
        if (!this.isAddressValid || (this.account && !this.isAccountValid)) {
            return;
        }
        super.validateAndSave();
        this.loading = false;
    }

    async submitAndReturn(val: boolean | Client): Promise<void> {
        if (typeof val === 'boolean') {
            this.modal.close(val);
            return;
        }
        val.address = this.address;

        if (!this.isAccountEmpty) {
            val.account = this.account;
        }

        if (this.isEditMode) {
            // update object if is edit mode, because submitAndReturn is used in form controls, where user can add new value or edit existing one.
            await this.clientsService.update(this.objectId, Object.assign(this.originalObject, val));
        }
        this.modal.close(val);
    }

    cancel(): void {
        this.modal.destroy();
    }

    get isAccountEmpty(): boolean {
        return !this.account || Object.keys(this.account).length === 0;
    }
}
