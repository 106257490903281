import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Classifier, ClassifierType, CommandResult } from '@lui/shared/models';
import { ClassifiersFilter } from '@lui/shared/models/classifiers-filter.model';
import { Observable } from 'rxjs/internal/Observable';
import { BaseServiceWithFilter } from './base.service';

interface ClasifierMapPair {
    key: ClassifierType;
    apiEndpoint: string;
    name: string;
}

@Injectable({ providedIn: 'root' })
export class ClassifierService extends BaseServiceWithFilter<Classifier, ClassifiersFilter> {
    classifierMap: ClasifierMapPair[] = [
        { key: ClassifierType.Groups, apiEndpoint: 'groups', name: 'Grupas' },
        { key: ClassifierType.GroupPermissions, apiEndpoint: 'group-permissions', name: 'Grupu tiesības' },
        { key: ClassifierType.Permissions, apiEndpoint: 'permissions', name: 'Tiesības' },
        { key: ClassifierType.Sexes, apiEndpoint: 'sexes', name: 'Dzimumi' },
        { key: ClassifierType.PersonTypes, apiEndpoint: 'person-types', name: 'Personu tipi' },
        { key: ClassifierType.Vat, apiEndpoint: 'vat', name: 'PVN' },
        { key: ClassifierType.InvoiceShowAccount, apiEndpoint: 'invoice-show-account', name: 'Konti rēķinā' },
        { key: ClassifierType.ClientTypes, apiEndpoint: 'client-types', name: 'Klientu veidi' },
        { key: ClassifierType.PrintoutTypes, apiEndpoint: 'printout-types', name: 'Izdruku veidi' },
        { key: ClassifierType.OwnershipTypes, apiEndpoint: 'ownership-types', name: 'Personas īpašumtiesību veidi' },
        { key: ClassifierType.AdvancePaymentTypes, apiEndpoint: 'advance-payment-types', name: 'Priekšapmaksu veidi' },
        //{ key: ClassifierType.EstimateStatus, apiEndpoint: 'estimate_status', name: 'Piedāvājuma statusi' },
        //{ key: ClassifierType.EstimateAdditionalInfo, apiEndpoint: 'estimate_additional_info', name: 'Piedāvājuma papildus info' },
    ];

    constructor(httpClient: HttpClient) {
        super(httpClient, '/api/v1/classifiers/');
    }

    getTypes(): Promise<Classifier[]> {
        return this.httpClient.get<Classifier[]>('/api/v1/classifiers').toPromise();
    }

    getClassifiers(classifierType: ClassifierType): Observable<Classifier[]> {
        const apiEndpoint = this.getClassifierApiEndpoint(classifierType, true);
        return this.httpClient.get<Classifier[]>(apiEndpoint);
    }

    createClassifier(classifierType: ClassifierType, classifier: Classifier): Promise<number> {
        const apiEndpoint = this.getClassifierApiEndpoint(classifierType);
        return this.httpClient.post<number>(`${apiEndpoint}`, classifier).toPromise();
    }

    updateClassifier(classifierType: ClassifierType, id: number, classifier: Classifier): Promise<void> {
        const apiEndpoint = this.getClassifierApiEndpoint(classifierType);
        return this.httpClient.put<void>(`${apiEndpoint}/${id}`, classifier).toPromise();
    }

    private getClassifierApiEndpoint(classifierType: ClassifierType, child: boolean = false): string {
        const apiEndpoint = this.classifierMap.find((c) => c.key === classifierType);
        if (!apiEndpoint) {
            throw new Error('Classifier type not found');
        }

        return child ? '/api/v1/classifiers/child/' + apiEndpoint.key : '/api/v1/classifiers/' + apiEndpoint.key;
    }

    getClassifierName(classifierType: ClassifierType): string {
        const classifier = this.classifierMap.find((c) => c.key === classifierType);
        if (!classifier) {
            return '';
        }
        return classifier.name;
    }

    isOriginalLuidaisIdUnique(parentId: number, originalLuidaisId: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`/api/v1/classifiers/${originalLuidaisId}/luidais-id-unique/${parentId}`);
    }

    searchBetweenClassifierGroup(parentId: number, text: string, searchInName: boolean): Observable<CommandResult<Classifier[]>> {
        return this.httpClient.get<CommandResult<Classifier[]>>(`${this.baseApiUrl}search/${parentId}/${text}/${searchInName}`);
    }
}
