import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PrintoutType } from '@lui/shared/models/classifier.model';
import { PrintoutPlaceholder } from '@lui/shared/models/printout-placeholder.model';
import { PrintoutTemplateFilter } from '@lui/shared/models/printout-template-filter.model';
import { PrintoutTemplate } from '@lui/shared/models/printout-template.model';
import { Observable } from 'rxjs';
import { BaseServiceWithFilter } from './base.service';

@Injectable({ providedIn: 'root' })
export class PrintoutTemplateService extends BaseServiceWithFilter<PrintoutTemplate, PrintoutTemplateFilter> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/api/v1/printout-templates/');
    }

    getPlaceholders(printoutType: PrintoutType): Observable<PrintoutPlaceholder[]> {
        return this.httpClient.get<PrintoutPlaceholder[]>(`${this.baseApiUrl}placeholders/${printoutType}`);
    }

    getTemplates(printoutType: PrintoutType): Observable<PrintoutTemplate[]> {
        return this.httpClient.get<PrintoutTemplate[]>(`${this.baseApiUrl}templates/${printoutType}`);
    }

    getTemplatesForOrderType(printoutType: PrintoutType, orderTypeId: number): Observable<PrintoutTemplate[]> {
        return this.httpClient.get<PrintoutTemplate[]>(`${this.baseApiUrl}templates/${printoutType}/${orderTypeId}`);
    }
}
