import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseServiceWithFilter } from './base.service';
import { BaseLayer } from '@lui/shared/models/base-layer.model';
import { lastValueFrom } from 'rxjs';
import { BaseLayerFilter } from '@lui/shared/models/base-layer-filter.model';

@Injectable({ providedIn: 'root' })
export class BaseLayersService extends BaseServiceWithFilter<BaseLayer, BaseLayerFilter> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/api/v1/base-layers/');
    }

    async getActiveBaseLayers(): Promise<BaseLayer[]> {
        return await lastValueFrom(this.httpClient.get<BaseLayer[]>(this.baseApiUrl + 'active'));
    }
}
