import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseServiceWithFilter } from './base.service';
import { Performer } from '@lui/shared/models/performer.model';
import { CommandResult } from '@lui/shared/models/command-result.model';
import { Observable } from 'rxjs';
import { PerformerFilter } from '@lui/shared/models/performer-filter.model';

@Injectable({ providedIn: 'root' })
export class PerformersService extends BaseServiceWithFilter<Performer, PerformerFilter> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/api/v1/performers/');
    }

    getUnlinked(): Observable<CommandResult<Performer[]>> {
        return this.httpClient.get<CommandResult<Performer[]>>(this.baseApiUrl + 'unlinked');
    }

    getCompanyPerformersByDepartment(departmentId: number): Observable<CommandResult<Performer[]>> {
        return this.httpClient.get<CommandResult<Performer[]>>(`${this.baseApiUrl}department-company-performers/${departmentId}`);
    }

    getCompanyPerformers(companyId: number): Observable<CommandResult<Performer[]>> {
        return this.httpClient.get<CommandResult<Performer[]>>(`${this.baseApiUrl}company-performers/${companyId}`);
    }

    isPersonCodeUnique(code: string, id: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.baseApiUrl}${code}/unique/${id}`);
    }

    searchBetweenPerformersRelatedToUser(text: string): Observable<CommandResult<Performer[]>> {
        return this.httpClient.get<CommandResult<Performer[]>>(`${this.baseApiUrl}search-related/${text}`);
    }

    performerToString(performer: Performer): string {
        return `${performer.fullName} (${performer.departmentName})`;
    }
}
