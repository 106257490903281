import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseServiceWithFilter } from './base.service';
import { EstimatePositionType } from '../../shared/models/estimate-position-type.model';
import { CommandResult } from '@lui/shared/models/command-result.model';
import { lastValueFrom } from 'rxjs';
import { EstimatePositionTypeFilter } from '@lui/shared/models/estimate-position-type-filter.model';

@Injectable({ providedIn: 'root' })
export class EstimatePositionTypesService extends BaseServiceWithFilter<EstimatePositionType, EstimatePositionTypeFilter> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/api/v1/estimate-position-types/');
    }


    getEstimatePositionTypesForOrder(ordersTypeId: number): Promise<CommandResult<EstimatePositionType[]>> {
        return lastValueFrom(this.httpClient
            .get<CommandResult<EstimatePositionType[]>>(`${this.baseApiUrl}for-order/${ordersTypeId}`));
    }
}
