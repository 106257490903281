<nz-spin [nzSpinning]="loading" *ngIf="objectForm">
    <form nz-form [formGroup]="objectForm" class="login-form" nzLabelAlign="left">
        <nz-form-item>
            <nz-form-label nzRequired nzFor="personTypeId" [nzSm]="8" [nzXs]="24">Personas tips</nz-form-label>
            <nz-form-control luiValidationError="personTypeId" [nzSm]="16" [nzXs]="24">
                <nz-select nzShowSearch nzPlaceHolder="Personas tips" formControlName="personTypeId">
                    <nz-option *ngFor="let p of personTypes$ | async" [nzLabel]="p.name" [nzValue]="p.originalLuidaisId"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label nzFor="clientTypeId" [nzSm]="8" [nzXs]="24">Klienta tips</nz-form-label>
            <nz-form-control luiValidationError="clientTypeId" [nzSm]="16" [nzXs]="24">
                <nz-select nzShowSearch nzPlaceHolder="Klienta tips" formControlName="clientTypeId">
                    <nz-option *ngFor="let ct of clientTypes$ | async" [nzLabel]="ct.name" [nzValue]="ct.originalLuidaisId"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label nzRequired nzFor="sex" [nzSm]="8" [nzXs]="24">Dzimums</nz-form-label>
            <nz-form-control luiValidationError="sex" [nzSm]="16" [nzXs]="24">
                <nz-select nzShowSearch nzPlaceHolder="Dzimums" formControlName="sex">
                    <nz-option *ngFor="let s of sexes$ | async" [nzLabel]="s.name" [nzValue]="s.name"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label nzRequired [nzSm]="8" [nzXs]="24" nzFor="name">Vārds</nz-form-label>
            <nz-form-control luiValidationError="name" [nzSm]="16" [nzXs]="24">
                <input type="text" nz-input id="name" formControlName="name" placeholder="Vārds" />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label nzRequired nzFor="surname" [nzSm]="8" [nzXs]="24">Uzvārds</nz-form-label>
            <nz-form-control luiValidationError="surname" [nzSm]="16" [nzXs]="24">
                <input type="text" nz-input id="surname" formControlName="surname" placeholder="Uzvārds" />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label nzRequired nzFor="code" [nzSm]="8" [nzXs]="24"
                >{{ selectedPersonType == personTypes.Physical ? 'Personas kods' : 'Reģistrācijas nr.' }}
            </nz-form-label>
            <nz-form-control
                [nzErrorTip]="codeErrorTip"
                [nzSuccessTip]="codeSuccessTip"
                nzHasFeedback
                nzValidatingTip="Pārbauda..."
                [nzSm]="16"
                [nzXs]="24"
            >
                <input
                    type="text"
                    nz-input
                    id="code"
                    formControlName="code"
                    [placeholder]="selectedPersonType == personTypes.Physical ? 'Personas kods' : 'Reģistrācijas nr.'"
                />
                <ng-template #codeErrorTip let-control>
                    <ng-container *ngIf="control.hasError('required')">
                        {{ selectedPersonType == personTypes.Physical ? 'Ievadiet personas kodu' : 'Ievadiet reģistrācijas nr.' }}
                    </ng-container>
                    <ng-container *ngIf="control.hasError('pattern')">
                        {{ selectedPersonType == personTypes.Physical ? 'Kļūdains personas kods' : 'Kļūdains reģistrācijas nr.' }}
                    </ng-container>
                    <ng-container *ngIf="control.hasError('duplicated')">
                        {{
                            selectedPersonType == personTypes.Physical
                                ? 'Šāds personas kods jau ir reģistrēts'
                                : 'Šāds reģistrācijas nr. jau ir reģistrēts'
                        }}
                    </ng-container>
                </ng-template>
                <ng-template #codeSuccessTip let-control>
                    <nz-alert
                        nzType="info"
                        *ngIf="selectedPersonType == personTypes.Physical && !control.value?.startsWith('32')"
                        nzMessage="Tiek izmantots 'vecā' tipa personas kods"
                    ></nz-alert>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label nzRequired nzFor="phone" [nzSm]="8" [nzXs]="24">Telefons</nz-form-label>
            <nz-form-control luiValidationError="phone" [nzSm]="16" [nzXs]="24">
                <input type="text" nz-input id="phone" formControlName="phone" placeholder="Telefons" />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label nzFor="clientEmail" [nzSm]="8" [nzXs]="24">Epasts</nz-form-label>
            <nz-form-control luiValidationError="clientEmail" [nzSm]="16" [nzXs]="24">
                <input type="email" nz-input id="clientEmail" formControlName="clientEmail" placeholder="Epasts" />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label nzFor="bookkeeperEmail" [nzSm]="8" [nzXs]="24">Grāmatvedības epasts</nz-form-label>
            <nz-form-control luiValidationError="bookkeeperEmail" [nzSm]="16" [nzXs]="24">
                <input type="email" nz-input id="bookkeeperEmail" formControlName="bookkeeperEmail" placeholder="Grāmatvedības epasts" />
            </nz-form-control>
        </nz-form-item>

        <lui-upsert-field
            [fieldId]="'addressId'"
            [fieldLabel]="'Adrese'"
            [(object)]="address"
            [viewModel]="address.street"
            [isRequired]="true"
            [isSubmitPerformed]="isSubmitPerformed"
            [upsertComponent]="upsertAddressComponent"
            [fieldErrors]="fieldErrors"
            (validChange)="isAddressValid = $event"
            [isEditMode]="isEditMode"
        ></lui-upsert-field>

        <lui-upsert-field *ngIf="account"
            [fieldId]="'accountId'"
            [fieldLabel]="'Konts'"
            [(object)]="account"
            [viewModel]="account.accountNr"
            [isRequired]="false"
            [isSubmitPerformed]="isSubmitPerformed"
            [upsertComponent]="upsertAccountComponent"
            [fieldErrors]="fieldErrors"
            (validChange)="isAccountValid = $event"
            [isEditMode]="isEditMode"
        ></lui-upsert-field>

        <nz-form-item>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="notes">Piezīmes</nz-form-label>
            <nz-form-control [nzSm]="16" [nzXs]="24">
                <textarea rows="5" nz-input id="notes" formControlName="notes" placeholder="Piezīmes"></textarea>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label nzFor="isBlocked" [nzSm]="8" [nzXs]="24" [nzNoColon]="true"></nz-form-label>
            <nz-form-control [nzSm]="16" [nzXs]="24">
                <label nz-checkbox id="isBlocked" formControlName="isBlocked">Bloķēts</label>
            </nz-form-control>
        </nz-form-item>

        <div *nzModalFooter>
            <button nz-button nzType="default" [disabled]="loading" (click)="cancel()">Atcelt</button>
            <button nz-button nzType="primary" [disabled]="loading" (click)="validateAndSave()">Saglabāt</button>
        </div>
    </form>
</nz-spin>
