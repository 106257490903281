import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Contact } from '@lui/shared/models/contact.model';
import { BaseServiceWithFilter } from './base.service';
import { Observable } from 'rxjs';
import { ContactsFilter } from '@lui/shared/models/contacts-filter.model';

@Injectable({ providedIn: 'root' })
export class ContactsService extends BaseServiceWithFilter<Contact, ContactsFilter> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/api/v1/contacts/');
    }

    isPersonCodeUnique(code: string, id: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.baseApiUrl}${code}/unique/${id}`);
    }
}
